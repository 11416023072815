import './App.css';
import { Layout } from 'antd';
import AddDataStoreRow from './components/AddDataStoreRow';

const { Header, Content, Footer } = Layout;

function App() {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ color: 'white', textAlign: 'center' }}>Pretty Header</Header>
      <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <AddDataStoreRow />
      </Content>
      <Footer style={{ textAlign: 'center' }}>&copy; 2024 Proper Footer. All rights reserved.</Footer>
    </Layout>    
  );
}

export default App;
