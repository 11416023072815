import React, { useState } from 'react';
import { Row, Col, Button, Form, Input, DatePicker, Space, message } from 'antd';
import dayjs from 'dayjs';

const AddDataStoreRow = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const key = 'message';

    const showSuccess = () => {
        messageApi.open({
            key,
            type: 'success',
            content: 'Saved',
        });
    };    
    
    const showError = () => {
        messageApi.open({
            key,
            type: 'success',
            content: 'Error saving data',
        });
    };    

    const onFinish = async (values) => {

        const name = values.name;
        const birthDate = dayjs(values.birthDate).format('YYYY-MM-DD');
        const url = '../server/foo/datastore';
        const data = { name: name, birthDate: birthDate };

        setLoading(true);

        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            form.resetFields();
            await res.json();
            showSuccess();
        } catch (error) {
            showError();
            console.error('Error:', error);
        }

        setLoading(false);
    };

    return (
        <>
            {contextHolder}
            <Row justify="center" align="middle" style={{ height: '100%' }}>
                <Col>
                    <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    >
                        <Form.Item
                        label="First Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input your name' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                        label="Birth Date"
                        name="birthDate"
                        rules={[{ required: true, message: 'Please input your birth date' }]}
                        >
                            <DatePicker />
                        </Form.Item>

                        <Form.Item>
                            <Space>
                                <Button type="primary" loading={loading} htmlType="submit">
                                    Save
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>            
                </Col>
            </Row>
        </>
    );
};

export default AddDataStoreRow;